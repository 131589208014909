import React from "react"
import Layout from "../components/App/Layout"
import SEO from "../components/App/SEO"
import NavbarStyleTwo from "../components/App/NavbarStyleTwo"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import { Link } from "gatsby"

// import PrivacyPolicyImg from "../assets/images/blog/blog-img2.jpg"
import PrivacyPolicyImg from "../assets/images/privacy.png"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />

      {/* <NavbarStyleTwo /> */}

      <PageBanner pageTitle="Privacy Policy" />

      <div className="privacy-policy-area bg-f9f9f9 ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="privacy-policy-content">
                <img src={PrivacyPolicyImg} alt="Privacy Policy" />
                <p>
                  <i>This Privacy Policy was last updated on May 19, 2021.</i>
                </p>
                <h3>1.個人情報の定義</h3>
                <p>
                  Magical
                  Appは、個人情報とは、個人情報の保護に関する法律に規定される生存する個人に関する情報（氏名、生年月日、その他の特定の個人を識別することができる情報）、
                  ならびに特定の個人と結びついて使用されるメールアドレス、ユーザーＩＤ、パスワード、クレジットカードなどの情報、
                  および個人情報と一体となった趣味、家族構成、年齢その他の個人に関する属性情報であると認識しています。
                </p>
                <h3>２．クッキー・IPアドレス情報</h3>
                <p>
                  クッキー及びＩＰアドレス情報については、それら単独では特定の個人を識別することができないため、個人情報とは考えておりません。ただしこれら情報と個人情報が一体となって使用される場合にはこれら情報も個人情報とみなします。
                </p>
                <h3>３．個人情報利用目的の特定</h3>
                <p>
                  Magical
                  Appは、個人情報を取り扱うにあたって、その利用の目的を出来る限り特定します。
                </p>
                <h3>４．個人情報利用の制限</h3>
                <p>
                  Magical
                  Appは、あらかじめご本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取扱うことはありません。ただし、次の場合はこの限りではありません。
                </p>
                <ol>
                  <li>法令に基づく場合</li>
                  <li>
                    人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難であるとき
                  </li>
                  <li>
                    公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得
                    ることが困難であるとき
                  </li>
                  <li>
                    国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協
                    力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                  </li>
                </ol>
                <h3>5．個人情報の取得に際する利用目的の通知</h3>
                <p>
                  Magical
                  Appは、個人情報を取得するにあたり、あらかじめその利用目的を公表します。ただし、次の場合はこの限りではありません。
                </p>
                <ul>
                  <li>
                    利用目的をご本人に通知し、または公表することによりご本人または第三者の生命、身体、財産その他の
                    権利利益を害するおそれがある場合
                  </li>
                  <li>
                    利用目的をご本人に通知し、または公表することによりMagical
                    Appの権利または正当な利益を 害するおそれがある場合
                  </li>
                  <li>
                    国の機関もしくは地方公共団体が法令の定める事務を遂行することに対して協力する必要がある場合であ
                    って、利用目的をご本人に通知し、または公表することにより当該事務の遂行に支障を及ぼすおそれがあるとき
                  </li>
                  <li>
                    取得の状況からみて利用目的が明らかであると認められる場合
                  </li>
                </ul>
                <br />
                <br />
                <h4>
                  <p>
                    その他プライバシーポリシーに関するお問い合わせは、下記までお願い致します。
                  </p>
                  <p>info@magical-app.com</p>
                </h4>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <div className="widget-area">
                <div className="widget widget_insight">
                  <ul>
                    {/* <li>
                      <Link to="/about-us-1">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li> */}
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li className="active">
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    {/* <li>
                      <Link to="/terms-of-service">Terms of Service</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default PrivacyPolicy
